/**
 * @file Skills.tsx
 * @description This file contains the Skills component, which is used to display the skills section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/skills.css';
import { SkillCategoryType, skills } from '../data/constants';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/**
 * Skills component
 * @returns Skills component
 */

export function Skills() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    return (
        <section id='skills'>
            <div className='page-wrapper'>
                <div className='skills-container'>
                    <div className='skill-intro'>
                        <h2>Skills</h2>
                        <p className='subtitle'>
                            My skills include a variety of technologies and tools that I’ve gained from working on
                            different projects. I focus on practical solutions in areas like frontend development,
                            backend programming, and design.
                        </p>
                    </div>
                    <div className='skill-container'>
                        <Slider {...settings}>
                            {skills.map((category: SkillCategoryType, index: number) => (
                                <SkillCard key={index} content={category} />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

/**
 * SkillCard component
 * @param title Title of the skill card
 * @param desc Description of the skill card
 * @returns SkillCard component
 */
export function SkillCard({ content }: { content: SkillCategoryType }) {
    return (
        <div className='skill-card'>
            <h3 className='cat-title'>{content.title}</h3>
            <div className='card-content'>
                {content.skills.map((skill, index) => (
                    <div className='skill' key={index}>
                        <img className='skill-image' src={skill.image} alt={skill.name} />
                        <p className='skill-name'>{skill.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
