/**
 * @file Education.tsx
 * @description This file contains the Education component, which is used to display the education section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/education.css';
import { EducationInfo, educations } from '../data/constants';
import { useEffect, useRef, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

let globalOrder = '';

/**
 * Education component
 * @returns Education component
 */
export function Education() {
    const eduLeftRef = useRef(null);

    const [order, setOrder] = useState<'right' | 'alternate'>('alternate');

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            setOrder(screenWidth < 1020 ? 'right' : 'alternate');
            globalOrder = screenWidth < 1020 ? 'right' : 'alternate';
        };

        // Call the function initially to set the state
        handleResize();

        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run only on mount and unmount

    useEffect(() => {
        const options = {
            root: null, // relative to the viewport
            rootMargin: '0px', // 50% from the bottom of the viewport
            threshold: [0.5], // trigger when 50% of the element is visible
        };

        const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (order === 'right') {
                        entry.target.classList.add('fade-in-right');
                    } else {
                        entry.target.classList.add('fade-in-left');
                    }
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (eduLeftRef.current) {
            observer.observe(eduLeftRef.current);
        }

        return () => {
            if (eduLeftRef.current) {
                observer.unobserve(eduLeftRef.current);
            }
        };
    }, []);

    return (
        <section id='education'>
            <div className='page-wrapper'>
                <div className='edu-box'>
                    <div className='edu-left' ref={eduLeftRef}>
                        <h2>Education</h2>
                        <p className='subtitle'>
                            My educational journey has provided me with valuable insights and knowledge, shaping my
                            skills and perspectives. Here are the details of my education.
                        </p>
                    </div>
                    <div className='edu-right'>
                        <Timeline
                            position={order}
                            sx={
                                order === 'right'
                                    ? {
                                          [`& .${timelineItemClasses.root}:before`]: {
                                              flex: 0,
                                              padding: 0,
                                          },
                                      }
                                    : undefined
                            }
                        >
                            {educations.map((education: EducationInfo, index: number) => (
                                <TimelineItem key={index}>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <EducationCard content={education} key={index} index={index} />
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </div>
                </div>
            </div>
        </section>
    );
}

/**
 * EducationCard component
 * @param title Title of the education card
 * @param desc Description of the education card
 * @returns EducationCard component
 */
export function EducationCard({ content, index }: { content: EducationInfo; index: number }) {
    const eduCardRef = useRef<HTMLDivElement | null>(null);

    // Determine the animation class based on the index
    const fadeInClass = index % 2 === 0 ? 'fade-in-right' : 'fade-in-left';

    useEffect(() => {
        const options = {
            root: null, // relative to the viewport
            rootMargin: '0px 0px -10% 0px', // 50% from the bottom of the viewport
            threshold: [0.5], // trigger when 50% of the element is visible
        };

        const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (globalOrder === 'right') {
                        entry.target.classList.add('fade-in-right');
                    } else {
                        entry.target.classList.add(fadeInClass);
                    }
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (eduCardRef.current) {
            observer.observe(eduCardRef.current);
        }

        return () => {
            if (eduCardRef.current) {
                observer.unobserve(eduCardRef.current);
            }
        };
    }, [fadeInClass]);

    return (
        <div className='edu-card' ref={eduCardRef}>
            <div className='edu-top'>
                <img className='edu-img' src={content.img} alt={content.school} />
                <div className='edu-info'>
                    <h3 className='edu-school'>{content.school}</h3>
                    <p className='edu-degree'>{content.degree}</p>
                    <span className='edu-date'>{content.date}</span>
                </div>
            </div>
            <div className='h-line' />
            <span className='edu-desc'>{content.desc}</span>
        </div>
    );
}
