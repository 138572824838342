import React, { useState } from 'react';
import '../styles/components/navigator.css';
import { Bio } from '../data/constants';

/**
 * Navigator component
 * @returns Navigator component
 */
export function Navigator() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <nav>
            <div className='mobile-container'>
                <a href='#about' className='logo-container'>
                    <img src='PortfolioLogo.png' alt='Portfolio logo' className='logo' />
                    <span className='logo-title'>Portfolio</span>
                </a>
                <div className={`nav-container ${isMobileMenuOpen ? 'open' : ''}`}>
                    <NavigatorElement toggleMenu={closeMobileMenu} title='Skills' targetSection='#skills' />
                    <NavigatorElement toggleMenu={closeMobileMenu} title='Experience' targetSection='#experience' />
                    <NavigatorElement toggleMenu={closeMobileMenu} title='Education' targetSection='#education' />
                    <NavigatorElement toggleMenu={closeMobileMenu} title='Contact' targetSection='#contact' />
                </div>
                <a className='social-button' href={Bio.linkedin}>
                    LinkedIn
                </a>
                <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu} tabIndex={0}>
                    <span className='bar'></span>
                    <span className='bar'></span>
                    <span className='bar'></span>
                </div>
            </div>
        </nav>
    );
}

/**
 * NavigatorElement component
 * @param title Title of the navigator element
 * @param targetSection Target Section of the navigator element
 * @returns NavigatorElement component
 */
function NavigatorElement({ toggleMenu, title, targetSection }: { toggleMenu?: () => void; title: string; targetSection: string }) {
    return (
        <a href={targetSection} className='nav-item' onClick={toggleMenu}>
            {title}
        </a>
    );
}
