/**
 * @file Contact.tsx
 * @description This file contains the Contact component, which is used to display the contact section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/contact.css';
import React from 'react';

/**
 * Contact component
 * @returns Contact component
 */
export function Contact() {
    const [result, setResult] = React.useState('Send');
    const formRef = React.useRef<HTMLFormElement>(null);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setResult('Sending....');
        const formData = new FormData(event.currentTarget);

        formData.append('access_key', '8203b297-c18c-437b-88ed-b91ca2a32809');

        const response = await fetch('https://api.web3forms.com/submit', {
            method: 'POST',
            body: formData,
        });

        const data = await response.json();

        if (data.success) {
            setResult('Form Submitted Successfully');
            if (formRef.current) {
                formRef.current.reset();
                setTimeout(() => setResult('Send'), 1500);
            }
        } else {
            setResult('Form is not working at the moment');
        }
    };

    return (
        <div className='page-wrapper'>
            <section id='contact'>
                <div className='contact-form'>
                    <h2>Contact Me</h2>
                    <p className='subtitle'>Feel free to reach out to me for any questions or opportunities!</p>
                    <div className='h-line' />
                    <form ref={formRef} onSubmit={onSubmit}>
                        <input className='form-name' placeholder='Your Name' type='text' name='name' required />
                        <input className='form-email' placeholder='Your Email' type='email' name='email' required />
                        <input className='from-subject' placeholder='Subject' type='text' name='subject' />
                        <textarea className='from-message' placeholder='Message' name='message' required></textarea>
                        <button className='form-button' type='submit'>
                            <span>{result}</span>
                        </button>
                    </form>
                </div>
            </section>
        </div>
    );
}
