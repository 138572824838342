export interface BioType {
    name: string;
    roles: string[];
    description: string;
    github: string;
    linkedin: string;
    image: string;
}

export const Bio: BioType = {
    name: 'Sebastiaan Koedam',
    roles: ['Fullstack Developer', 'Company Co-Founder', 'Informatics Specialist', 'Student', '3d Generalist'],
    description:
        "My name is Sebastiaan Koedam, I am 21 years old, and I am studying HBO-ICT Technical Informatics at the Hogeschool van Amsterdam. Recently I've started a software development company called getgood, where we focus on building websites and providing services related to hosting and servers. Alongside my studies and work with getgood, I am also looking for side projects and freelance opportunities to gain more experience in the tech field.",
    github: 'https://github.com/SebasKoedam',
    linkedin: 'https://www.linkedin.com/in/sebas-koedam/',
    image: 'pf.jpeg',
};

export interface SkillType {
    name: string;
    image: string;
}

export interface SkillCategoryType {
    title: string;
    skills: SkillType[];
}

export const skills: SkillCategoryType[] = [
    {
        title: 'Frontend',
        skills: [
            {
                name: 'React TS',
                image: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K',
            },
            {
                name: 'JavaScript',
                image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png',
            },
            {
                name: 'CSS',
                image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png',
            },
            {
                name: 'HTML',
                image: 'https://www.w3.org/html/logo/badge/html5-badge-h-solo.png',
            },
        ],
    },
    {
        title: 'Backend',
        skills: [
            {
                name: 'Node.js',
                image: 'https://nodejs.org/static/images/logo.svg',
            },
            {
                name: 'MySQL',
                image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg',
            },
            {
                name: 'PHP',
                image: 'https://www.php.net/images/logos/new-php-logo.svg',
            },
            {
                name: 'Apache',
                image: 'https://www.vectorlogo.zone/logos/apache/apache-icon.svg',
            },
        ],
    },
    {
        title: 'Android',
        skills: [
            {
                name: 'Java',
                image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg',
            },
            {
                name: 'XML',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBMw6_RdwKQ9bDFfnKDX1iwMl4bVJEvd9PP53XuIw&s',
            },
            {
                name: 'Android Studio',
                image: 'https://developer.android.com/static/studio/images/new-studio-logo-1_1920.png',
            },
        ],
    },
    {
        title: 'Embedded',
        skills: [
            {
                name: 'Python',
                image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg',
            },
            {
                name: 'C++',
                image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg',
            },
        ],
    },
    {
        title: '3d Design',
        skills: [
            {
                name: 'Inventor',
                image: 'https://icons.veryicon.com/png/System/Simply%20Styled/Autodesk%20Inventor.png',
            },
            {
                name: 'Fusion 360',
                image: 'https://seeklogo.com/images/A/autodesk-fusion-360-logo-7F72A76397-seeklogo.com.png',
            },
            {
                name: 'AutoCAD',
                image: 'https://seeklogo.com/images/A/autocad-logo-C9817CB828-seeklogo.com.png',
            },
            {
                name: 'Cura',
                image: 'https://img.utdstc.com/icon/97a/a5e/97aa5e531fee9e0b18d7028609d091062db6e392c49e45859ba0aeeeb118eae4:100',
            },
        ],
    },
    {
        title: 'Others',
        skills: [
            {
                name: 'GitHub',
                image: 'https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png',
            },
            {
                name: 'VS Code',
                image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519',
            },
            {
                name: 'Word',
                image: 'https://cdn.worldvectorlogo.com/logos/word-1.svg',
            },
            {
                name: 'Excel',
                image: 'https://cdn.worldvectorlogo.com/logos/excel-4.svg',
            },
        ],
    },
];

export interface EducationInfo {
    img: string;
    school: string;
    date: string;
    desc: string;
    degree: string;
}

export const educations: EducationInfo[] = [
    {
        img: 'hva-logo.png',
        school: 'Hogeschool van Amsterdam, Amsterdam',
        date: 'Sep 2023 - Present',
        desc: 'I am currently studying for a Bachelor’s degree in HBO ICT at Hogeschool van Amsterdam. I am working on interesting projects and learning about new technologies in the field of information technology.',
        degree: 'Bachelor, HBO ICT',
    },
    {
        img: 'roc-logo.png',
        school: 'ROC van Amsterdam, Hilversum',
        date: 'Aug 2019 - Jun 2023',
        desc: 'I completed my MBO 4 in Technicus Engineering Mechatronica at ROC van Amsterdam. This study taught me about mechanical and electronic systems and gave me practical engineering skills.',
        degree: 'MBO niveau 4, Technicus Engineering Mechatronica',
    },
    {
        img: 'arhc-logo.jpg',
        school: 'A. Roland Holst College, Hilversum',
        date: 'Aug 2015 - Jun 2019',
        desc: 'I finished my VMBO-TL at A. Roland Holst College in Hilversum. This education provided a good foundation in various subjects and prepared me for further studies.',
        degree: 'VMBO-TL',
    },
];


export interface ExperienceInfo {
    role: string;
    date: string;
    desc: string;
    skills: string[];
}

export interface CompanyExperience {
    company: string;
    img: string;
    companyUrl?: string;
    experiences: ExperienceInfo[];
}

export const experiences: CompanyExperience[] = [
    {
        company: 'getgood',
        img: 'getgood-logo.png',
        companyUrl: 'https://getgood.nl',
        experiences: [
            {
                role: 'Co-founder',
                date: 'June 2024 - Present',
                desc: 'As a co-founder of getgood, I oversee various aspects of our software development and web hosting services. I manage projects, and ensure the delivery of high-quality solutions to our clients. My role involves coordinating development efforts, optimizing hosting services, implementing SEO strategies to enhance online visibility and much more.',
                skills: [
                    'Apache',
                    'Application Hosting',
                    'CSS',
                    'Databases',
                    'Email Hosting',
                    'Google Indexing',
                    'Hosting Services',
                    'JavaScript',
                    'React TypeScript',
                    'MariaDB',
                    'Node.js',
                    'PHPMyAdmin',
                    'SEO Optimization',
                    'Servers',
                    'Web Hosting',
                    'Project Management',
                    'Sales Management',
                    'Time Management'
                ],
            }
        ],
    },
    {
        company: 'Lyv',
        img: 'lyv-logo.jpg',
        experiences: [
            {
                role: 'Intern',
                date: 'January 2023 - June 2023',
                desc: 'Worked on designing a 500 kWh battery system for a client.',
                skills: ['Project Management', 'Client Relations', 'Communication'],
            },
            {
                role: 'Intern',
                date: 'August 2022 - November 2022',
                desc: 'Service Engineer, gained knowledge about the company and its products.',
                skills: [
                    'AutoCAD',
                    '3D Design',
                    'Communication',
                    'Understanding of: Batteries, Solar Panels, IoT',
                ],
            },
        ],
    },
    {
        company: 'SODAQ',
        img: 'sodaq-logo.webp',
        experiences: [
            {
                role: 'Junior Engineering Technician',
                date: 'April 2021 - April 2022',
                desc: 'Handled quality service, performed product analysis, and gained experience during the internship.',
                skills: ['Product Analysis', 'Quality Assurance', 'Communication'],
            },
            {
                role: 'Intern',
                date: 'August 2021 - January 2022',
                desc: 'Worked on quality assurance tasks.',
                skills: ['Embedded Devices', 'Product Quality Maintenance'],
            },
            {
                role: 'Intern',
                date: 'January 2021 - April 2021',
                desc: 'Focused on industrial design projects.',
                skills: ['3D Design', '3D Printing', 'Fusion 360', 'Prusa Slicer'],
            },
        ],
    },
];

