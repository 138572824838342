/**
 * @file Experience.tsx
 * @description This file contains the Experience component, which is used to display the experience section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/experience.css';
import { ExperienceInfo, experiences } from '../data/constants';
import React, { useEffect, useRef } from 'react';

/**
 * Experience component
 * @returns Experience component
 */
export function Experience() {
    const expLeftRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null, // relative to the viewport
            rootMargin: '0px 0px -10% 0px', // 50% from the bottom of the viewport
            threshold: [0.8], // trigger when 50% of the element is visible
        };

        const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                    const expContainer = document.querySelector('.exp-container');
                    if (expContainer) {
                        expContainer.classList.add('scroll');
                    }
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (expLeftRef.current) {
            observer.observe(expLeftRef.current);
        }

        return () => {
            if (expLeftRef.current) {
                observer.unobserve(expLeftRef.current);
            }
        };
    }, []);

    return (
        <section id='experience'>
            <div className='page-wrapper'>
                <div className='experience-container'>
                    <div className='exp-left' ref={expLeftRef}>
                        <h2>Experience</h2>
                        <p className='subtitle'>
                            My work experience, which includes working on various projects at different companies and
                            being a Co-Founder at a software development company, is as follows.
                        </p>
                    </div>
                    <div className='exp-right'>
                        {experiences.map(companyExperience => (
                            <div key={companyExperience.company} className='exp-card'>
                                <div className='company-content'>
                                    <div className='company-info'>
                                        <img
                                            className='exp-image'
                                            src={companyExperience.img}
                                            alt={companyExperience.company}
                                        />
                                        <h2 className='exp-company'>{companyExperience.company}</h2>
                                    </div>
                                    {companyExperience.companyUrl && (
                                        <button
                                            className='exp-company-button'
                                            onClick={() => window.open(companyExperience.companyUrl, '_blank')}
                                        >
                                            getgood
                                        </button>
                                    )}
                                </div>
                                <div className='exp-container'>
                                    {companyExperience.experiences.map((experience, index) => (
                                        <ExperienceCard
                                            key={index}
                                            content={experience}
                                            company={companyExperience.company}
                                            img={companyExperience.img}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

/**
 * ExperienceCard component
 * @param title Title of the experience card
 * @param desc Description of the experience card
 * @returns ExperienceCard component
 */
export function ExperienceCard({ content, company, img }: { content: ExperienceInfo; company: string; img: string }) {
    const skillRefs = useRef<(HTMLLIElement | null)[]>([]);

    useEffect(() => {
        const highlightSkills = (index: number) => {
            if (index >= skillRefs.current.length) {
                index = 0; // Reset to start if all skills have been highlighted
            }

            const currentSkillRef = skillRefs.current[index];
            if (currentSkillRef) {
                currentSkillRef.classList.add('highlight');
                
                // Remove highlight after 0.5 seconds (500 ms)
                setTimeout(() => {
                    currentSkillRef.classList.remove('highlight');
                    // Move to the next skill after a delay
                    highlightSkills(index + 1);
                }, 3000);
            }
        };

        highlightSkills(0); // Start highlighting from the first skill

        // Optional: Clear highlight if component unmounts
        return () => {
            skillRefs.current.forEach(skillRef => {
                if (skillRef) {
                    skillRef.classList.remove('highlight');
                }
            });
        };
    }, [content.skills]);

    return (
        <div className='exp-content'>
            <div className='h-line' />
            <div className='exp-box'>
                <div className='box-left'>
                    <div className='exp-top'>
                        <div className='exp-body'>
                            <h3 className='exp-role'>{content.role}</h3>
                            <p className='exp-date'>{content.date}</p>
                        </div>
                    </div>
                    <span className='exp-desc'>{content.desc}</span>
                </div>
                <div className='box-right'>
                    <h4>Skills acquired</h4>
                    <ul className='exp-skills'>
                        {content.skills.map((skill, index) => (
                            <li 
                                key={index} 
                                className='exp-skill' 
                                ref={el => skillRefs.current[index] = el}
                            >
                                {skill}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
